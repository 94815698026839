import React, {Fragment} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom';
import {CarrouselComponente} from '../components/CarrouselComponente';
import { FooterComponente } from '../components/FooterComponente';
import {NavBarComponente} from '../components/NavBarComponente';
import HomeScreen from '../pages/HomeScreen';

export const AppRouter = () => {

    return (
        <Fragment>
            <NavBarComponente/>
            <CarrouselComponente/>
                <Routes>
                    <Route path="/" element={< HomeScreen />}/>
                    <Route path="/*" element={< Navigate to = '/' replace />}/>
                </Routes>
               
             <FooterComponente/>   
           
        </Fragment>
    )
}
