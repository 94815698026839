import React, {Fragment, useRef, useState} from 'react'
import {galeriaData} from '../data/galeriaData';
import {images} from '../helpers/imagenes';

export const GaleriaScreen = () => {
    const [referencia, setreferencia] = useState();
    const ref= useRef(null as any);

    const handleClick = (e: any) => {
     
        setreferencia(e.target.baseURI + e.target.attributes.src.value.replace('/',''));
        
    }
        
        
    return (

        <Fragment>

            {/* aqui va el codigo de la galeria */}

            <div className="container">
                <h1 className='text-center'>Galeria de Algunos Trabajos</h1>

                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {galeriaData.map((imagen, index) => (
                        <div key={index} className="col">

                            <div className="card h-100">
                                <button
                                ref={ref}
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                    onClick={handleClick}
                                   
                                    >
                                    <img
                                        id={`${index}`}
                                        className="card-img-top"
                                        src={images(`./galeria/${ imagen.imagen}`)}
                                        alt="imagenes"/>
                                </button>

                                {/* Modal */}
                                <div
                                    className="modal fade"
                                    id="staticBackdrop"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false"
                                    tabIndex={-1}
                                    aria-labelledby="staticBackdropLabel"
                                    aria-hidden="true">
                                    <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <img
                                                    className="card-img-top"
                                                    src={referencia} 
                                                    alt="imagenes"/>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}

                </div>
            </div>

        </Fragment>

    )
}
