export const clientesData = [
    {
        id:'A1',
        nombre:'AC FARMA S.A.',
        imagen:'A1.png',
    },
    {
        id:'A2',
        nombre:'AXON GROUP',
        imagen:'A2.png',
    },
    {
        id:'A3',
        nombre:'BBVA',
        imagen:'A3.jpg',
    },
    {
        id:'A4',
        nombre:'BCP',
        imagen:'A4.jpg',
    },
    {
        id:'A5',
        nombre:'FUERZA AEREA DEL PERU',
        imagen:'A5.png',
    },
    {
        id:'A6',
        nombre:'INDUSTRIAS ALIMENTARIAS SAC',
        imagen:'A6.png',
    },
    {
        id:'A7',
        nombre:'FEDERACICIÓN DEPORTIVA PERUANA DE TENIS',
        imagen:'A7.jpg',
    },
    {
        id:'A8',
        nombre:'VOTORANTIM METAIS - CAJAMARQUILLA S.A.',
        imagen:'A8.jpg',
    },
    {
        id:'A9',
        nombre:'SEDAPAL',
        imagen:'A9.jpg',
    },
    {
        id:'A10',
        nombre:'PROVIAS NACIONAL',
        imagen:'A10.jpg',
    },
    
    {
        id:'A11',
        nombre:'NETWORK DATA SOLUTIONS',
        imagen:'A11.png',
    },
    {
        id:'A12',
        nombre:'AGRYNOVA S.A.C.',
        imagen:'A12.png',
    },

]