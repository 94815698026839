import React, {Fragment} from 'react'
import {clientesData} from '../data/clientesData';
import {clientes} from '../helpers/imagenes';

export const ClientesComponente = () => {
    return (
        <Fragment >
            <div id='clientes' className="bg-dark">
                <div className="container">
                    <h1 className='text-center g-4 text-white '>Alguno de Nuestros clientes</h1>
                    <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6 g-4">
                        {clientesData.map(cliente => (
                            <div key={cliente.id} className="col">
                                <div className="card">
                                    <img
                                        src={clientes(`./${cliente.imagen}`)}
                                        className="card-img-top img-fluid"
                                        alt={cliente.nombre}
                                        width='25%'
                                        height='25%'/>
                                    <div className="card-body">
                                        <h6 className="card-title fs-6 text-center">{cliente.nombre}</h6>
                                    </div>
                                </div>
                            </div>
                        ))
}

                    </div>

                </div>
            </div>
        </Fragment>
    )
}