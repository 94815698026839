import React from 'react'

export const ContactosComponente = () => {
  return (
    <div id="contacto" className="container-fluid mt-5 mb-3 bg-success text-white p-5">
              <div className="ubicacion  container">
                  <h2 className=" text-uppercase titulo">Contacto</h2>

                  <div className="direccion">
                      <p> <span className="text-uppercase font-weight-bold">Direccion :</span> San Isaias 179 - SMP</p>
                      <p> <span className="text-uppercase font-weight-bold">Telefono:</span> 923235674</p>
                      <p> <span className="text-uppercase font-weight-bold">Celular:</span> 923235674</p>
                      <p> <span className="text-uppercase font-weight-bold">Correo :</span>presupuestos@proyecasa.com</p>

                  </div>
              </div>

                 
          </div>
  )
}
