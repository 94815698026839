import React, {Fragment} from 'react';
import {images} from '../helpers/imagenes';
import {bannerData} from '../data/bannerData';

export const CarrouselComponente = () => {
    
    return (
        <Fragment>
            <div
                id="inicio"
                className="carousel slide"
                data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {
                        bannerData.map((banner, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#inicio"
                                data-bs-slide-to={`${index}`}
                                className={`${(index === 0) ? 'active' : ''}`}
                                aria-current={`${ (index === 0)
                                ? true
                                : false}`}
                                aria-label={`Slide ${banner.id}`}></button>
                        ))
                    }
                </div>
                <div className="carousel-inner">

                    {bannerData.map((banner, index) => {
                        return (
                            <div
                                key={index}
                                className={`${ (index === 0)
                                ? 'carousel-item active'
                                : 'carousel-item '}`}>
                                <img
                                    src={images(`./banner/${banner.imagen}`)}
                                    className="d-block w-100"
                                    alt="..."/>
                                <div className="carousel-caption d-none d-md-block">
                                    <h1>{banner.titulo}</h1>
                                    <p>{banner.descripcion}</p>
                                </div>
                            </div>
                        )
                    })}

                </div>
                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#inicio"
                    data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#inicio"
                    data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </Fragment>
    )
}
