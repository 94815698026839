import React, {Fragment, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import {images} from '../helpers/imagenes';

export const NavBarComponente = () => {

    const refScroll = useRef(null as any);
    /*====  Inicio de cambiar color scroll  ====*/
    //cambia de color el fondo al hacer el scroll javascript nativo
    window.onscroll = function (e) {
        const scroll = window.scrollY;

        if (scroll > 400) {
            refScroll
                .current
                .classList
                .add('bg-success');
        } else {
            console.log("no");
            refScroll
                .current
                .classList
                .remove("bg-success");
        }
    };

    return (
        <Fragment>
            <nav
                id="navbar-example"
                ref={refScroll}
                className="navbar nav-pills navbar-expand-lg navbar-light bg-transparent fixed-top">
                <div className="container">

                    <img
                        src={images('./logo/logo.png')}
                        alt="logo"
                        width="40%"
                        className=' navbar-brand  img-fluid'/>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul
                            className=" navbar-nav me-auto mb-2 mb-lg-0 justify-content-center   w-100 justify-content-md-end ">
                            <li className="nav-item">
                                <NavLink to="inicio" className='nav-link' aria-current="page">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className='nav-link' to="servicios">Servicios</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to='galerias' className='nav-link'>Galeria</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='contacto' className='nav-link'>Contacto</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='clientes' className='nav-link'>Clientes</NavLink>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>

        </Fragment>
    )
}
