export const bannerData = [
    {
        id: 1,
        imagen: '1.jpg',
        titulo: 'Estructurado parabólico',
        descripcion: 'Estructurado parabólico para cielo raso de pvc'
    }, {
        id: 2,
        imagen: '2.jpg',
        titulo: 'planchado final parabólico',
        descripcion: 'planchado final parabólico de pvc'
    }, {
        id: 3,
        imagen: '3.jpg',
        titulo: 'Armado de estructuras con perfilerias',
        descripcion: 'Perfiles para drywall galvanizados, tienen una altura estándar de 35 a 70mm y una altura a pedido de 54 a 105mm'
    }, {
        id: 4,
        imagen: '4.jpg',
        titulo: 'Planchado de estructuras con paneles de yeso',
        descripcion: ' paneles de yeso en las vigas del techo de forma permanente. Usa clavos o tornillos para fijar los paneles a las vigas. Coloca los tornillos pasadores a 0,95 cm (0,37 pulgadas) de distancia de los bordes de cada panel y deja un espacio de 18 cm (7 pulgadas) a lo largo del diámetro'

    },
    {
        id: 5,
        imagen: '5.jpg',
        titulo: 'Empastado de Tabiques',
        descripcion: 'Empastado y lijado de muros drywall'
    },
    {
        id: 6,
        imagen: '6.jpg',
        titulo: 'Pinturas de Tabiques',
        descripcion: 'Pintura de muros drywall acabados'
    },
    {
        id: 7,
        imagen: '7.jpg',
        titulo: 'Instalación de aire acondicionado',
        descripcion: 'Instalación de aire acondicionado y mantenimiento'
    },
    {
        id: 8,
        imagen: '8.jpg',
        titulo: 'Aislamiento de falso techo',
        descripcion: 'Aislamiento con fibra de vidrio y/o fibra roca de cielo raso'
    },
    {
        id: 9,
        imagen: '9.jpg',
        titulo: 'Instalación de baldosas de yeso',
        descripcion: 'Instalación de cielo raso de yeso '
    },
    {
        id: 10,
        imagen: '10.jpg',
        titulo: 'Cielo raso de fibra mineral',
        descripcion: 'Cielo raso de fibra mineral'
    },
    {
        id: 11,
        imagen: '11.jpg',
        titulo: 'Cielo raso de fibra de vidrio',
        descripcion: 'Cielo raso de fibra de vidrio'
    },
    {
        id: 12,
        imagen: '12.jpg',
        titulo: 'Instalación de Tapizado',
        descripcion: 'Instalación de piso tapizado'
    },
    {
        id: 13,
        imagen: '13.jpg',
        titulo: 'Colocación de paneles de PVC',
        descripcion: 'Colocación de paneles de PVC En industrias '
    },
    {
        id: 14,
        imagen: '14.jpg',
        titulo: 'Instalación de ceramicas',
        descripcion: 'Instalación de ceramicas en muros y pisos '
    },
    {
        id: 15,
        imagen: '15.jpg',
        titulo: 'Pintura en edificios',
        descripcion: 'Pintura en edificios en general'
    }
]