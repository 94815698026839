import React, {Fragment} from 'react'
import {videos} from '../helpers/imagenes';
import {videosData} from '../data/videosData';

export const ServiciosScreen = () => {
    return (
        <Fragment>
            <div  className="container">
                <h1 className='text-center g-4 '>Nuestros servicios</h1>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {videosData.map(video => (
                        <div key={video.id} className="col">

                            <div className="card h-100">
                                <video src={videos(`./${video.video}`)} width="100%" controls></video>
                                <div className="card-body">
                                    <h5 className="card-title text-center text-primary">{video.title}</h5>
                                    <p className="card-text   fs-6">{video.description}</p>
                                </div>
                            </div>
                        </div>

                    ))}

                </div>

            </div>
        </Fragment>
    )
}