

export const galeriaData = [
    {
        id:1,
        imagen:'A1.jpg'
    },
    {
        id:2,
        imagen:'A2.jpg'
    },
    {
        id:3,
        imagen:'A3.jpg'
    },
    {
        id:4,
        imagen:'B1.jpg'
    },
    {
        id:5,
        imagen:'B2.jpg'
    },
    {
        id:6,
        imagen:'B3.jpg'
    },
    {
        id:7,
        imagen:'B4.jpg'
    },
    {
        id:8,
        imagen:'B5.jpg'
    },
    {
        id:9,
        imagen:'B6.jpg'
    },
    {
        id:10,
        imagen:'B7.jpg'
    },
    {
        id:11,
        imagen:'B8.jpg'
    },
    {
        id:12,
        imagen:'B9.jpg'
    },
    {
        id:13,
        imagen:'B10.jpg'
    },
    {
        id:14,
        imagen:'C1.jpg'
    },
    {
        id:15,
        imagen:'C2.jpg'
    },
    {
        id:16,
        imagen:'C3.jpg'
    },
    {
        id:16,
        imagen:'C4.jpg'
    },
    {
        id:17,
        imagen:'D1.jpg'
    },
    {
        id:18,
        imagen:'D2.jpg'
    },
    {
        id:19,
        imagen:'D3.jpg'
    },
    {
        id:20,
        imagen:'D4.jpg'
    },
    {
        id:21,
        imagen:'E1.jpg'
    },
    {
        id:22,
        imagen:'E2.jpg'
    },
    {
        id:23,
        imagen:'E3.jpg'
    },
    {
        id:24,
        imagen:'E4.jpg'
    },
    {
        id:25,
        imagen:'E5.jpg'
    },
    {
        id:26,
        imagen:'E6.jpg'
    },
    {
        id:27,
        imagen:'F1.jpg'
    },
    {
        id:28,
        imagen:'G1.jpg'
    },
    {
        id:28,
        imagen:'G2.jpg'
    },
    {
        id:29,
        imagen:'G3.jpg'
    },
    {
        id:30,
        imagen:'G4.jpg'
    },
    {
        id:31,
        imagen:'G5.jpg'
    },
    {
        id:32,
        imagen:'H1.jpg'
    },
    {
        id:33,
        imagen:'H2.jpg'
    },
    {
        id:34,
        imagen:'H3.jpg'
    },
    {
        id:35,
        imagen:'H4.jpg'
    },
    {
        id:36,
        imagen:'I1.jpg'
    },
    {
        id:37,
        imagen:'I2.jpg'
    },
    {
        id:38,
        imagen:'I3.jpg'
    },
    {
        id:39,
        imagen:'J1.jpg'
    },
    
    
    
    
    
]