import React  from 'react'
import { ClientesComponente } from '../components/ClientesComponente'
import { GaleriaScreen } from '../components/GaleriaComponente'
import { ServiciosScreen } from '../components/ServiciosComponente'

import { ContactosComponente } from '../components/ContactosComponente';

const HomeScreen = () => {
  return (
    <div   >
      <ServiciosScreen/>
      <GaleriaScreen/>
      <ContactosComponente/>
      <ClientesComponente/>
    
    </div>
  )
}

export default HomeScreen