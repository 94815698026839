import React from 'react'


export const FooterComponente = () => {
    return (

        <footer className="container-fluid bg-primary text-white">
            <div className="text-center footer py-3">
                <p>© www.proyecasa.com Todo los derechos reservados | Desarrollado por PZM</p>
            </div>

        </footer>

    )
}
