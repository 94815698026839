export const videosData = [
    {
        id: 'drywall',
        title: 'Construcciones en Drywall',
        description: 'Hacemos trabajos en Drywall está destinada a solucionar pequeños y grandes problemas en el área de mantenimiento integral, además de la elaboración de proyectos técnicos en distintas áreas. Colocamos a su disposición toda la infraestructura necesaria para la realización del mantenimiento de instalaciones y servicios en: Drywall. Con planchas de Driwall de calidad Resistente al Juego, resistente a la Humedad, y placa Estándar. Instalación profesional en drywall de techos y paredes para todo tipo de comercios, oficinas, industrias, casas y departamentos. Remodele y cree espacios modernos.',
        video: 'drywall.mp4'
    }, {
        id: 'aireacondicionado',
        title: 'Instalaciones y Mantenimiento de Aire Acondicionado',
        description: 'Sistema de aire acondicionado Agua helada, enfriado por Agua, expansión directa,' +
                ' VRV o VRF',
        video: 'aireacondicionado.mp4'
    }, {
        id: 'cieloraso',
        title: 'Instalaciones de Cieloraso',
        description: 'El sistema de Cielos Rasos  ha sido especialmente desarrollado para brindar una solución práctica y económica de óptimo desempeño acústico. Además, de ser una solución segura, ofrecen muchas ventajas, diseño modular, excelente estabilidad.La baldosa es un producto de acabado para cielos rasos diseñados para ofrecer soluciones eficientes y confort acústico en los ambientes',
        video: 'cieloraso.mp4'
    }, {
        id: 'electricidad',
        title: 'Instalaciones y mantenimiento Electrico',
        description: 'Realizamos instalaciones eléctricas tanto industriales y domesticos: Mantenimientos a empresas y comunidades, instalaciones eléctricas en centros comerciales, oficinas,locales,viviendas,telecomunicaciones y todo tipo de entidades oficiales(hospitales, ayuntamientos, colegios, etc.)Realizamos nuestros trabajos con profesionalidad y seriedad,cumpliendo estrictamente con los plazos de entrega acordados.Gestión de proyectos Industrial Instalaciones eléctricas a nivel industrial tanto para obra nueva,reparaciones o mantenimiento de la instalación eléctrica mayoritariamente en todo el país. instalaciones nuevas,reparaciones eléctricas o mantenimiento de la instalación.Doméstica Realizamos instalaciones eléctricas a nivel doméstico,para reparar,o realizar una remodelación o reparación. ',
 video: 'electricidad.mp4'
    }, {
        id: 'enchapadopintura',
        title: 'Enchapado y Pintura',
        description: 'Si Tienes un proyecto en colocar pisos en su casa, oficina, empresa o proyecto inmobiliario  estás pensando en una remodelación te brindamos servicios en instalar Pisos porcelanato, mayólicas, mármol  y revestimiento de Muros y Zócalos.Instalación de pisos laminados o flotantes  ideales para, salones, vestíbulos, pasillos, dormitorios, salas de juego, habitaciones de hotel, salas de reuniones, aulas, centros comerciales, colegios, etc.Solicite su presupuesto sin compromiso.',
        video: 'enchapadopintura.mp4'
    }, {
        id: 'civiles',
        title: 'Obras Civiles y Construcciones en General',
        description: 'Proyecasa es una empresa especializada en el rubro de la construcción, donde rea' +
                'lizamos todo tipo de trabajos como: tarrajeo de paredes, tarrajeo de cielo raso,' +
                ' solaqueo, llenado de techos, zanjas, remodelaciones, ampliaciones, enchapes cer' +
                'ámico y porcelanato,Instalaciones eléctricas, instalaciones sanitarias y pintura' +
                'Y acabados en general.Te proporcionamos presupuestos accesibles. Todos nuestros ' +
                'servicios se encuentran garantizados por la calidad y experiencia que nos respal' +
                'dan',
        video: 'civiles.mp4'
    }
]